import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    IonButton, IonCard, IonCardHeader, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel,
    IonRow, IonSelect, IonSelectOption, IonText, IonTitle
} from '@ionic/react';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';

import { GetABMClients } from '../../store/ABMClients/ABMClientsActions';
import { ABMClientsDefaultStateI } from '../../store/ABMClients/ABMClientsReducer';
import { GetABMKpisGroups } from '../../store/ABMMetric/ABMKpisGroups/ABMKpisGroupsActions';

import ApiRequestor from '../../api_access/ApiRequestor';

import './ABMMetric.css';
import Modal from '../Modal/Modal';

interface DataModify {
    kpi_group_id: number,
    kpi_group_name: string,
    kpi_group_created_by: string,
    kpi_group_updated_by: string,
    service_id: number,
    service_name: string,
    kpi_legend: string
}

interface Props extends StateTypes, dispatchTypes {
    kpiGroupData: DataModify;
    backToTable: () => void;
}

const custiomSelectOptions = {
    cssClass: 'customSelectKpiGroup'
}

const ModifyGroupsABMKpis: React.FC<Props> = (props): JSX.Element => {

    const [showModalAddGroups, setShowModalAddGroups] = useState(false);
    const [kpisGroupData, setKpisGroupData] = useState({
        client: props.kpiGroupData.service_id,
        name: props.kpiGroupData.kpi_group_name,
        legend: props.kpiGroupData.kpi_legend,
        id: props.kpiGroupData.kpi_group_id
    })

    const [errors, setErrors] = useState({ nombre: ''});
    const nameRegex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(\s[a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/;

    useEffect(() => {
        props.getClients()
    }, [])

    const saveData = (e: any) => {
        setKpisGroupData({
            ...kpisGroupData,
            [e.target.name]: e.detail.value!
        })
    }

    const validateForm = () => {
        if (!kpisGroupData.name.trim()) {
            setErrors(prevErrors => ({
                ...prevErrors, 
                nombre: 'El campo "Nombre" no debe ser vacío'
            }))
            return false
        }else if(!nameRegex.test(kpisGroupData.name)){
            setErrors(prevErrors => ({
                ...prevErrors, 
                nombre: 'No se permiten números ni caracteres especiales, ni espacios al inicio o al final'
            }))
            return false
        } else {
            return true
        }
    }

    const modifyKpisGroup = async () => {

        let valid = validateForm()

        if (valid) {
            let data: any = {
                body: {
                    kpi_group_id: kpisGroupData.id,
                    kpi_group_name: kpisGroupData.name,
                    kpi_legend: kpisGroupData.legend,
                    service_id: kpisGroupData.client
                }
            }
            const response = await ApiRequestor.requestToApi('/swt-admin/kpi-groups', 'PUT', data)
            if (response && response.status === 200) {
                setShowModalAddGroups(true)
            }
        } else {
            validateForm()
        }
    }

    const onModalDismiss = () => {
        setShowModalAddGroups(false)
        props.getABMKpisGroups()
        props.backToTable()
    }

    return (
        <IonContent fullscreen >
            <IonCard className="ABMKpisCardFormAdd">
                <IonCardHeader>
                    <IonTitle className="ABMKpisTitleKpi">MODIFICACIÓN GRUPO DE KPIS</IonTitle>
                </IonCardHeader>
                <IonGrid className='ABMKpisADD'>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Cliente</IonLabel>
                            <IonItem lines='none' disabled>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='name'>
                                    {sessionStorage.getItem("client")}
                                </IonInput>
                            </IonItem>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Nombre del Grupo de Kpis</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    placeholder="Nombre grupo Kpis"
                                    name='name'
                                    value={kpisGroupData.name}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText><p className={errors.nombre ? "login-valid-text" : "ion-hide"}>{errors.nombre}</p></IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                    <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Leyenda del Grupo de Kpis</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    placeholder="Leyenda grupo Kpis"
                                    name='legend'
                                    value={kpisGroupData.legend}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center containerButtonKpis">
                        <IonButton type="submit" fill="clear" className='ABMKpisZoom' onClick={modifyKpisGroup}>
                            <IonIcon icon={checkmarkCircleOutline} color="success" className="ABMKpisButton" />
                        </IonButton>
                        <IonButton fill="clear" className='ABMKpisZoom' onClick={props.backToTable}>
                            <IonIcon icon={closeCircleOutline} color="danger" className="ABMKpisButton" />
                        </IonButton>
                    </IonRow>
                </IonGrid>
            </IonCard>

            <Modal
                modalConfirmation={{
                    textConfirmation: "Grupo de Kpis Modificado Correctamente",
                    onDidDimissConfirmModal: onModalDismiss,
                    isOpenModalConfirm: showModalAddGroups,
                    onClickConfirmModal: onModalDismiss,

                }}
            />

        </IonContent>
    )
}

//Redux state and actions dispatchers
interface StateTypes {
    ABMClients: ABMClientsDefaultStateI
}

const mapStatetoProps = (state: StateTypes) => {
    return {
        ABMClients: state.ABMClients
    }
}

interface dispatchTypes {
    getClients: () => void;
    getABMKpisGroups: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getClients: () => dispatch(GetABMClients()),
        getABMKpisGroups: () => dispatch(GetABMKpisGroups())
    };
}

export default connect(mapStatetoProps, mapDispatchToProps)(ModifyGroupsABMKpis);