import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    IonButton, IonCard, IonCardHeader, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel,
    IonRow, IonSelect, IonSelectOption, IonText, IonTitle
} from '@ionic/react';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';

import { GetABMIntern } from '../../store/ABMMetric/ABMIntern/ABMInternActions';
import { ABMClientsDefaultStateI } from '../../store/ABMClients/ABMClientsReducer';
import { GetABMClients } from '../../store/ABMClients/ABMClientsActions';

import ApiRequestor from '../../api_access/ApiRequestor';

import './ABMMetric.css';
import Modal from '../Modal/Modal';

interface DataIntern {
    intern_id: number;
    intern_name: string;
    intern_host: string;
    intern_field: string;
    intern_type: string;
    intern_transform: boolean;
    intern_endpoint_id: string;
    intern_threshold: boolean;
    intern_title: string;
    intern_title_key: string;
    intern_time_gap: number;
    intern_linechart_label: string;
    intern_category: string;
    intern_graphic: boolean;
    intern_created_by: string,
    intern_updated_by: string,
    service_id: string;
}

interface Props extends StateTypes, dispatchTypes {
    backToTable: () => void;
    internData: DataIntern
}

const custiomSelectOptions = {
    cssClass: 'customSelectKpiForm'
}

const AddABMIntern: React.FC<Props> = (props): JSX.Element => {

    const [internData, setInternData] = useState({
        intern_name: '', intern_host: '', intern_field: '', intern_type: '', intern_transform: '', intern_endpoint_id: '',
        intern_title: '', intern_title_key: '', intern_time_gap: '', intern_linechart_label: '', intern_category: '', intern_graphic: '', service_id: ''
    })
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [internId, setInternId] = useState();
    const [errors, setErrors] = useState({intern_name: '', intern_host: '', intern_field: '', intern_type: '',
        intern_transform:'', intern_endpoint_id:'', intern_title: '', intern_title_key: '', intern_time_gap:'',
        intern_linechart_label: '', intern_category: '', intern_graphic: ''
        })
    const nameRegex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9_%()\-]+(\s[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9_%()\-]+)*$/;
    const regularExpresionLegend = /^[^\s].*[^\s]$|^$/;
    const numRegex = /^\d+$/


    useEffect(() => {
        props.getClients()
    }, [])

    useEffect(() => {
        if (props.ABMClients.loading === false && props.ABMClients.abmclients) {
            props.ABMClients.abmclients.map((client: any) => {
                if (sessionStorage.getItem("client") === client.service_name) {
                    setInternId(client.service_id)
                }
            })
        }
    }, [sessionStorage.getItem("client"), props.ABMClients.loading, props.ABMClients.abmclients])

    const saveData = (e: any) => {
        setInternData({
            ...internData,
            [e.target.name]: e.detail.value!
        })
    }

    const validateForm = () => {

        const isEmpty = (value: any) => !value.trim();
        const isInvalidText = (value: any) => !nameRegex.test(value);
        const isInvalidNumber = (value: any) => !numRegex.test(value);
        const isInvalidLegend = (value: any) => !regularExpresionLegend.test(value);

        const errors = {
            intern_name: isEmpty(internData.intern_name)
            ? 'El campo "Nombre" no debe ser vacío'
            : isInvalidText(internData.intern_name)
            ? 'No se permiten espacios al inicio o al final'
            : '',
            intern_host: isEmpty(internData.intern_host)
            ? 'El campo "Host" no debe ser vacío'
            : isInvalidText(internData.intern_host)
            ? 'No se permiten espacios al inicio o al final'
            : '',
            intern_field: isEmpty(internData.intern_field)
            ? 'El campo "Campo" no debe ser vacío'
            : isInvalidText(internData.intern_field)
            ? 'No se permiten espacios al inicio o al final'
            : '',
            intern_type: isEmpty(internData.intern_type)
            ? 'Debe seleccionar uno'
            : '',
            intern_transform: internData.intern_transform === ''
            ? 'Debe seleccionar uno'
            : '',
            intern_endpoint_id: isEmpty(internData.intern_endpoint_id)
            ? 'Debe seleccionar uno'
            : '',
            intern_title: isEmpty(internData.intern_title)
            ? 'El campo "Titulo" no debe ser vacío'
            : isInvalidText(internData.intern_title)
            ? 'No se permiten espacios al inicio o al final'
            : '',
            intern_title_key: isEmpty(internData.intern_title_key)
            ? 'El campo "Key Titulo" no debe ser vacío'
            : isInvalidText(internData.intern_title_key)
            ? 'No se permiten espacios al inicio o al final'
            : '',
            intern_time_gap: isInvalidNumber(internData.intern_time_gap)
            ? 'Debe ingresar al menos un número'
            : '',
            intern_linechart_label: isInvalidLegend(internData.intern_linechart_label)
            ? 'No se permiten espacios al inicio o al final'
            : '',
            intern_category: sessionStorage.getItem("client") !== "DECIDIR"
            ? '' 
            : isEmpty(internData.intern_category)
            ? 'Debe seleccionar uno'
            :'',
            intern_graphic: internData.intern_graphic === ''
            ? 'Debe seleccionar uno'
            : ''
        }

        setErrors(errors as any);
        const hasErrors = Object.values(errors).some(error => error !== '');

        return hasErrors;
        }

    const creatIntern = async () => {

        validateForm();

        let valid = validateForm();

        if (!valid) {

            let data: any = {
                body: {
                    intern_name: internData.intern_name,
                    intern_host: internData.intern_host,
                    intern_field: internData.intern_field,
                    intern_type: internData.intern_type,
                    intern_transform: internData.intern_transform,
                    intern_endpoint_id: internData.intern_endpoint_id,
                    intern_threshold: true,
                    intern_title: internData.intern_title,
                    intern_title_key: internData.intern_title_key,
                    intern_time_gap: internData.intern_time_gap,
                    intern_linechart_label: internData.intern_linechart_label,
                    intern_graphic: internData.intern_graphic,
                    intern_category: internData.intern_category,
                    service_id: internId
                }
            }
            const response = await ApiRequestor.requestToApi('/swt-admin/intern', 'POST', data)
            if (response && response.status === 200) {
                setShowModalAdd(true)
            }
        } else {
            validateForm()
        }
    }

    const onDismissModal = () => {
        setShowModalAdd(false)
        props.getSeeABMIntern()
        props.backToTable()
    }

    return (
        <IonContent fullscreen >
            <IonCard className="ABMKpisCardForm">
                <IonCardHeader>
                    <IonTitle className="ABMKpisTitleKpi">ALTA DE MÉTRICA</IonTitle>
                </IonCardHeader>
                <IonGrid className='ABMKpisADD'>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Cliente</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    placeholder="Seleccione el Cliente"
                                    className="ABMKpisSelectKpis"
                                    name='service_id'
                                    value={sessionStorage.getItem("client")}
                                    disabled>
                                </IonInput>
                            </IonItem>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Nombre</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='intern_name'
                                    placeholder="Nombre"
                                    value={internData.intern_name}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText><p className={errors.intern_name? "login-valid-text" : "ion-hide"}>{errors.intern_name}</p></IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Host</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='intern_host'
                                    placeholder="Host"
                                    value={internData.intern_host}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText><p className={errors.intern_host ? "login-valid-text" : "ion-hide"}>{errors.intern_host}</p></IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Título</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='intern_title'
                                    placeholder="Título"
                                    value={internData.intern_title}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText><p className={errors.intern_title ? "login-valid-text" : "ion-hide"}>{errors.intern_title}</p></IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Key Título</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='intern_title_key'
                                    placeholder="Key Título"
                                    value={internData.intern_title_key}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText><p className={errors.intern_title_key ? "login-valid-text" : "ion-hide"}>{errors.intern_title_key}</p></IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Campo</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='intern_field'
                                    placeholder="Campo"
                                    value={internData.intern_field}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText><p className={errors.intern_field ? "login-valid-text" : "ion-hide"}>{errors.intern_field}</p></IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Type</IonLabel>
                            <IonItem lines='none'>
                                <IonSelect
                                    interface="popover"
                                    placeholder="Seleccione el tipo"
                                    className="ABMKpisSelectKpis"
                                    interfaceOptions={custiomSelectOptions}
                                    name='intern_type'
                                    value={internData.intern_type}
                                    onIonChange={(e) => saveData(e)}>
                                    <IonSelectOption value="QUANTITY">
                                        QUANTITY
                                    </IonSelectOption>
                                    <IonSelectOption value="PERCENTAGE">
                                        PERCENTAGE
                                    </IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonText><p className={errors.intern_type ? "login-valid-text" : "ion-hide"}>{errors.intern_type}</p></IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Transform</IonLabel>
                            <IonItem lines='none'>
                                <IonSelect
                                    interface="popover"
                                    placeholder="Seleccione"
                                    className="ABMKpisSelectKpis"
                                    name='intern_transform'
                                    interfaceOptions={custiomSelectOptions}
                                    value={internData.intern_transform}
                                    onIonChange={(e) => saveData(e)}>
                                    <IonSelectOption value={true}>
                                        TRUE
                                    </IonSelectOption>
                                    <IonSelectOption value={false}>
                                        FALSE
                                    </IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonText>
                                <p className={errors.intern_transform ? "login-valid-text" : "ion-hide"}>{errors.intern_transform}</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Endpoint</IonLabel>
                            <IonItem lines='none'>
                                <IonSelect
                                    interface="popover"
                                    placeholder="Seleccione"
                                    className="ABMKpisSelectKpis"
                                    name='intern_endpoint_id'
                                    interfaceOptions={custiomSelectOptions}
                                    value={internData.intern_endpoint_id}
                                    onIonChange={(e) => saveData(e)}>
                                    <IonSelectOption value="prod">
                                        PROD
                                    </IonSelectOption>
                                    <IonSelectOption value="dev">
                                        DEV
                                    </IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonText><p className={errors.intern_endpoint_id ? "login-valid-text" : "ion-hide"}>{errors.intern_endpoint_id}</p></IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Tiempo de Recarga(min)</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="number"
                                    name='intern_time_gap'
                                    placeholder="Tiempo de Recarga(min)"
                                    value={internData.intern_time_gap}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText>
                                <p className={errors.intern_time_gap ? "login-valid-text" : "ion-hide"}>{errors.intern_time_gap}</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Linechart Label</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='intern_linechart_label'
                                    placeholder="Linechart Label"
                                    value={internData.intern_linechart_label}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText>
                                <p className={errors.intern_linechart_label ? "login-valid-text" : "ion-hide"}>{errors.intern_linechart_label}</p>
                            </IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Gráfico</IonLabel>
                            <IonItem lines='none'>
                                <IonSelect
                                    interface="popover"
                                    placeholder="Seleccione"
                                    className="ABMKpisSelectKpis"
                                    name='intern_graphic'
                                    interfaceOptions={custiomSelectOptions}
                                    value={internData.intern_graphic}
                                    onIonChange={(e) => saveData(e)}>
                                    <IonSelectOption value={true}>
                                        TRUE
                                    </IonSelectOption>
                                    <IonSelectOption value={false}>
                                        FALSE
                                    </IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonText>
                                <p className={errors.intern_graphic ? "login-valid-text" : "ion-hide"}>{errors.intern_graphic}</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    {
                        sessionStorage.getItem("client") === "DECIDIR" &&
                        <IonRow>
                            <IonCol sizeXl="6" sizeXs='12'>
                                <IonLabel className="ABMKpisLabelKpis">Categoría</IonLabel>
                                <IonItem lines='none'>
                                    <IonSelect
                                        interface="popover"
                                        placeholder="Seleccione"
                                        className="ABMKpisSelectKpis"
                                        name='intern_category'
                                        interfaceOptions={custiomSelectOptions}
                                        value={internData.intern_category}
                                        onIonChange={(e) => saveData(e)}>
                                        <IonSelectOption value={"GENERAL"}>
                                            GENERAL
                                        </IonSelectOption>
                                        <IonSelectOption value={"MEDIOS DE PAGO"}>
                                            MEDIOS DE PAGO
                                        </IonSelectOption>
                                        <IonSelectOption value={"COMERCIOS"}>
                                            COMERCIOS
                                        </IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                                <IonText>
                                    <p className={errors.intern_category ? "login-valid-text" : "ion-hide"}>{errors.intern_category}</p>
                                </IonText>
                            </IonCol>
                        </IonRow>
                    }
                    <IonRow className="ion-justify-content-center containerButtonKpis">
                        <IonButton type="submit" fill="clear" className='ABMKpisZoom' onClick={creatIntern}>
                            <IonIcon icon={checkmarkCircleOutline} color="success" className="ABMKpisButton" />
                        </IonButton>
                        <IonButton fill="clear" className='ABMKpisZoom' onClick={props.backToTable}>
                            <IonIcon icon={closeCircleOutline} color="danger" className="ABMKpisButton" />
                        </IonButton>
                    </IonRow>
                </IonGrid>
            </IonCard>

            <Modal
                modalConfirmation={{
                    textConfirmation: "Métrica Creada Correctamente",
                    onDidDimissConfirmModal: onDismissModal,
                    isOpenModalConfirm: showModalAdd,
                    onClickConfirmModal: onDismissModal,
                }}
            />

        </IonContent>
    )

}

//Redux state and actions dispatchers

interface StateTypes {
    ABMClients: ABMClientsDefaultStateI
}

const mapStatetoProps = (state: StateTypes) => {
    return {
        ABMClients: state.ABMClients
    }
}

interface dispatchTypes {
    getClients: () => void;
    getSeeABMIntern: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getClients: () => dispatch(GetABMClients()),
        getSeeABMIntern: () => dispatch(GetABMIntern())
    };
}


export default connect(mapStatetoProps, mapDispatchToProps)(AddABMIntern);