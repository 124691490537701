import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
    IonButton, IonCard, IonCardHeader, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel,
    IonRow, IonSelect, IonSelectOption, IonText, IonTitle
} from '@ionic/react';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';

import { GetABMKpis } from '../../store/ABMMetric/ABMKpis/ABMKpisActions';

import ApiRequestor from '../../api_access/ApiRequestor';

import './ABMMetric.css';
import Modal from '../Modal/Modal';

interface DataKpiGroup {
    kpi_group_id: number,
    kpi_group_name: string,
    kpi_group_created_by: string,
    kpi_group_updated_by: string,
    service_id: number,
    service_name: string
}

interface Props extends StateTypes, dispatchTypes {
    backToKpisTable: () => void;
    kpiGroupData: DataKpiGroup
}

const custiomSelectOptions = {
    cssClass: 'customSelectKpiForm'
}

const AddABMKpis: React.FC<Props> = (props): JSX.Element => {

    const [kpiData, setKpiData] = useState({
        kpi_name: '', kpi_host: '', kpi_field: '', kpi_type: '', kpi_transform: '', kpi_endpoint_id: '',
        kpi_title: '', kpi_title_key: '', kpi_time_gap: '', kpi_linechart_label: ''
    })
    const [showModalAdd, setShowModalAdd] = useState(false);
    
    const [errors, setErrors] = useState({  kpi_name: '', kpi_host: '', kpi_field: '', kpi_type: '', kpi_transform: '',
        kpi_endpoint_id: '',kpi_title: '',kpi_title_key: '',kpi_time_gap: '',kpi_linechart_label: ''})
    const nameRegex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9_%()\-]+(\s[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9_%()\-]+)*$/;
    const regularExpresionLegend = /^[^\s].*[^\s]$|^$/;
    const numRegex = /^\d+$/;

    const saveData = (e: any) => {
        setKpiData({
            ...kpiData,
            [e.target.name]: e.detail.value!
        })
    }

    const validateForm = () => {
        const isEmpty = (value: any) => !value.trim();
        const isInvalidText = (value: any) => !nameRegex.test(value);
        const isInvalidNumber = (value: any) => !numRegex.test(value);
        const isInvalidLegend = (value: any) => !regularExpresionLegend.test(value);

        const errors = {
            kpi_name: isEmpty(kpiData.kpi_name)
            ? 'El campo "Nombre" no debe ser vacío'
            : isInvalidText(kpiData.kpi_name)
            ? 'No se permiten espacios al inicio o al final'
            : '',
            kpi_host: isEmpty(kpiData.kpi_host)
            ? 'El campo "Host" no debe ser vacío'
            : isInvalidText(kpiData.kpi_host)
            ? 'No se permiten espacios al inicio o al final'
            : '',
            kpi_field: isEmpty(kpiData.kpi_field)
            ? 'El campo "Campo" no debe ser vacío'
            : isInvalidText(kpiData.kpi_field)
            ? 'No se permiten espacios al inicio o al final'
            : '',
            kpi_type: isEmpty(kpiData.kpi_type)
            ? 'Debe seleccionar uno'
            : '',
            kpi_transform: kpiData.kpi_transform === ''
            ? 'Debe seleccionar uno'
            : '',
            kpi_endpoint_id: isEmpty(kpiData.kpi_endpoint_id)
            ? 'Debe seleccionar uno'
            : '',
            kpi_title: isEmpty(kpiData.kpi_title)
            ? 'El campo "Titulo" no debe ser vacío'
            : isInvalidText(kpiData.kpi_title)
            ? 'No se permiten espacios al inicio o al final'
            : '',
            kpi_title_key: isEmpty(kpiData.kpi_title_key)
            ? 'El campo "Key Titulo" no debe ser vacío'
            : isInvalidText(kpiData.kpi_title_key)
            ? 'No se permiten espacios al inicio o al final'
            : '',
            kpi_time_gap: isInvalidNumber(kpiData.kpi_time_gap)
            ? 'Debe ingresar al menos un número'
            : '',
            kpi_linechart_label: isInvalidLegend(kpiData.kpi_linechart_label)
            ? 'No se permiten espacios al inicio o al final'
            : '',
        }

        setErrors(errors as any);
        const hasErrors = Object.values(errors).some(error => error !== '');

        return hasErrors;
    }

    const creatKpi = async () => {
        validateForm();

        let valid = validateForm();
        
        if (!valid) {

            let data: any = {
                body: {
                    kpi_name: kpiData.kpi_name,
                    kpi_host: kpiData.kpi_host,
                    kpi_field: kpiData.kpi_field,
                    kpi_type: kpiData.kpi_type,
                    kpi_transform: kpiData.kpi_transform,
                    kpi_endpoint_id: kpiData.kpi_endpoint_id,
                    kpi_group_id: props.kpiGroupData.kpi_group_id,
                    kpi_threshold: true,
                    kpi_title: kpiData.kpi_title,
                    kpi_title_key: kpiData.kpi_title_key,
                    kpi_time_gap: kpiData.kpi_time_gap,
                    kpi_linechart_label: kpiData.kpi_linechart_label
                }
            }

            const response = await ApiRequestor.requestToApi('/swt-admin/kpis', 'POST', data)
            if (response && response.status === 200) {
                setShowModalAdd(true)
            }
        } else {
            validateForm()
        }

    }

    const onDismissModal = () => {
        setShowModalAdd(false)
        props.getSeeABMKpis()
        props.backToKpisTable()
    }

    return (
        <IonContent fullscreen >
            <IonCard className="ABMKpisCardForm">
                <IonCardHeader>
                    <IonTitle className="ABMKpisTitleKpi">ALTA DE KPI</IonTitle>
                </IonCardHeader>
                <IonGrid>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Cliente</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='client'
                                    placeholder="Nombre del Cliente"
                                    value={props.kpiGroupData.service_name}
                                    disabled />
                            </IonItem>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Nombre del Grupo de Kpis</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='kpiGroup'
                                    placeholder="Nombre del Grupo de Kpis"
                                    value={props.kpiGroupData.kpi_group_name}
                                    disabled />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Host</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='kpi_host'
                                    placeholder="Host"
                                    value={kpiData.kpi_host}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText><p className={errors.kpi_host ? "login-valid-text" : "ion-hide"}>{errors.kpi_host}</p></IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Nombre</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='kpi_name'
                                    placeholder="Nombre"
                                    value={kpiData.kpi_name}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText><p className={errors.kpi_name ? "login-valid-text" : "ion-hide"}>{errors.kpi_name}</p></IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Título</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='kpi_title'
                                    placeholder="Título"
                                    value={kpiData.kpi_title}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText><p className={errors.kpi_title ? "login-valid-text" : "ion-hide"}>{errors.kpi_title}</p></IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Key Título</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='kpi_title_key'
                                    placeholder="Key Título"
                                    value={kpiData.kpi_title_key}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText><p className={errors.kpi_title_key ? "login-valid-text" : "ion-hide"}>{errors.kpi_title_key}</p></IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Campo</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='kpi_field'
                                    placeholder="Campo"
                                    value={kpiData.kpi_field}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText><p className={errors.kpi_field ? "login-valid-text" : "ion-hide"}>{errors.kpi_field}</p></IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Type</IonLabel>
                            <IonItem lines='none'>
                                <IonSelect
                                    interface="popover"
                                    placeholder="Seleccione el tipo"
                                    className="ABMKpisSelectKpis"
                                    interfaceOptions={custiomSelectOptions}
                                    name='kpi_type'
                                    value={kpiData.kpi_type}
                                    onIonChange={(e) => saveData(e)}>
                                    <IonSelectOption value="QUANTITY">
                                        QUANTITY
                                    </IonSelectOption>
                                    <IonSelectOption value="PERCENTAGE">
                                        PERCENTAGE
                                    </IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonText>
                                <p className={errors.kpi_type ? "login-valid-text" : "ion-hide"}>{errors.kpi_type}</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Transform</IonLabel>
                            <IonItem lines='none'>
                                <IonSelect
                                    interface="popover"
                                    placeholder="Seleccione"
                                    className="ABMKpisSelectKpis"
                                    name='kpi_transform'
                                    interfaceOptions={custiomSelectOptions}
                                    value={kpiData.kpi_transform}
                                    onIonChange={(e) => saveData(e)}>
                                    <IonSelectOption value={true}>
                                        TRUE
                                    </IonSelectOption>
                                    <IonSelectOption value={false}>
                                        FALSE
                                    </IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonText>
                                <p className={errors.kpi_transform ? "login-valid-text" : "ion-hide"}>{errors.kpi_transform}</p>
                            </IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Endpoint</IonLabel>
                            <IonItem lines='none'>
                                <IonSelect
                                    interface="popover"
                                    placeholder="Seleccione"
                                    className="ABMKpisSelectKpis"
                                    name='kpi_endpoint_id'
                                    interfaceOptions={custiomSelectOptions}
                                    value={kpiData.kpi_endpoint_id}
                                    onIonChange={(e) => saveData(e)}>
                                    <IonSelectOption value="prod">
                                        PROD
                                    </IonSelectOption>
                                    <IonSelectOption value="dev">
                                        DEV
                                    </IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonText><p className={errors.kpi_endpoint_id ? "login-valid-text" : "ion-hide"}>{errors.kpi_endpoint_id}</p></IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Tiempo de Recarga(min)</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="number"
                                    name='kpi_time_gap'
                                    placeholder="Tiempo de Recarga(min)"
                                    value={kpiData.kpi_time_gap}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText><p className={errors.kpi_time_gap ? "login-valid-text" : "ion-hide"}>{errors.kpi_time_gap}</p></IonText>
                        </IonCol>
                        <IonCol sizeXl="6" sizeXs='12'>
                            <IonLabel className="ABMKpisLabelKpis">Linechart Label</IonLabel>
                            <IonItem lines='none'>
                                <IonInput
                                    className="ABMKpisInputKpis"
                                    type="text"
                                    name='kpi_linechart_label'
                                    placeholder="Linechart Label"
                                    value={kpiData.kpi_linechart_label}
                                    onIonChange={(e) => saveData(e)} />
                            </IonItem>
                            <IonText><p className={errors.kpi_linechart_label ? "login-valid-text" : "ion-hide"}>{errors.kpi_linechart_label}</p></IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center containerButtonKpis">
                        <IonButton className="ClassButtonKpis ABMKpisZoom" type="submit" fill="clear" onClick={creatKpi}>
                            <IonIcon icon={checkmarkCircleOutline} color="success" className="ABMKpisButton" />
                        </IonButton>
                        <IonButton className="ClassButtonKpis ABMKpisZoom" fill="clear" onClick={props.backToKpisTable}>
                            <IonIcon icon={closeCircleOutline} color="danger" className="ABMKpisButton" />
                        </IonButton>
                    </IonRow>
                </IonGrid>
            </IonCard>

            <Modal
                modalConfirmation={{
                    textConfirmation: "Kpi Creado Correctamente",
                    onDidDimissConfirmModal: onDismissModal,
                    isOpenModalConfirm: showModalAdd,
                    onClickConfirmModal: onDismissModal,
                }}
            />

        </IonContent>
    )
}

//Redux state and actions dispatchers

interface StateTypes {
}

const mapStatetoProps = (state: StateTypes) => {
    return {
    }
}

interface dispatchTypes {
    getSeeABMKpis: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getSeeABMKpis: () => dispatch(GetABMKpis())
    };
}

export default connect(mapStatetoProps, mapDispatchToProps)(AddABMKpis);