import React, { useState } from 'react';
import { connect } from 'react-redux';
import {IonButton, IonCard, IonCardTitle, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem,IonLabel, IonRow, IonSelect, IonSelectOption, IonText, IonToggle} from '@ionic/react';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';

import { GetABMClients } from '../../store/ABMClients/ABMClientsActions';

import Upload from './Upload';
import ApiRequestor from '../../api_access/ApiRequestor';
import Modal from '../Modal/Modal';
import './ABMClients.css';


interface Props extends StateTypes, dispatchTypes {
    backToTable: () => void;
}

const customSelectOptions = {
    cssClass: 'customSelectClients'
}

const AddABMClients: React.FC<Props> = (props): JSX.Element => {

    const [showModal, setShowModal] = useState(false);
    const [dataForm, setDataForm] = useState({
        name: '',
        id: '',
        maintenance: true,
        image: null,
        category: 'BANCOS'
    });
    const [errors, setErrors] = useState({ nombre: '', id: '' });
    const { name, id, maintenance, image, category } = dataForm;
    const nameRegex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(\s[a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/;
    const idRegex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+$/;



    const getDataForm = (e: any) => {
        setDataForm({
            ...dataForm,
            [e.target.name]: e.detail.value!
        })
    }
    const getToggleForm = (e: any) => {
        setDataForm({
            name: dataForm.name,
            id: dataForm.id,
            maintenance: !dataForm.maintenance,
            image: dataForm.image,
            category: dataForm.category
        })
    }
    const onImageUpload = (image: any) => {
        setDataForm({
            name: dataForm.name,
            id: dataForm.id,
            maintenance: dataForm.maintenance,
            image: image,
            category: dataForm.category
        })
    }

    
    const validationName = () => {

        if (!dataForm.name.trim()) {
            setErrors(prevErrors => ({
                ...prevErrors,
                nombre: 'El campo "Nombre" no debe ser vacío'
            }));
            return false;
        } else if(!nameRegex.test(dataForm.name)){
            setErrors(prevErrors => ({
                ...prevErrors,
                nombre: 'No se permiten números ni caracteres especiales, ni espacios al inicio o al final'
            }));
            return false;
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                nombre: ''
            }));
            return true;
        }
    }

    const validationID = () => {
        if (!dataForm.id.trim()) {
            setErrors(prevErrors => ({
                ...prevErrors,
                id: 'El campo "ID" no debe ser vacío'
            }));
            return false;
        } else if(!idRegex.test(dataForm.id)){
            setErrors(prevErrors => ({
                ...prevErrors,
                id: 'No se permiten números ni caracteres especiales ni espacios'
            }));
            return false;
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                id: ''
            }));
            return true;
        }
    }

    const createNewClient = async (e: any) => {
        e.preventDefault();

        const isNameValid = validationName();
        const isIDValid = validationID();

        if (isNameValid && isIDValid && category.length > 0){
            let data: any = {
                body: {
                    "service_name": dataForm.id,
                    "service_display_name": dataForm.name,
                    "service_maintenance": dataForm.maintenance,
                    "service_category": dataForm.category,
                    "service_image": dataForm.image
                }
            }
            const response: any = await ApiRequestor.requestToApi("/swt-admin/clients", "POST", data, "DATA", true);

            if (response && response.status === 200) {
                setShowModal(true);
            }

        } else {
            validationName()
            validationID()
        }
    }
    const dismissModal = () => {
        setShowModal(false);
        props.getABMClients()
        props.backToTable()
    }

    return (
        <IonContent fullscreen className="ion-padding">
            <IonCard className="ABMClientsFormCard">
                <IonCardTitle className="ion-text-center ABMClientsFormTitle">
                    ALTA DE CLIENTE
                </IonCardTitle>
                <IonGrid className="ion-margin ion-no-margin-top">
                    <IonRow>
                        <IonCol className="ABMClientsFormCol" sizeLg='6' sizeXs='12'>
                            <IonLabel className="ion-margin-vertical ABMClientsFormLabel">
                                Nombre
                            </IonLabel>
                            <IonItem className="ABMClientsFormItem" lines='none'>
                                <IonInput
                                    type="text"
                                    placeholder="Ingrese el Nombre"
                                    className="ion-padding ABMClientsFormInput"
                                    name="name"
                                    value={name}
                                    onIonChange={(e) => {
                                        validationName();
                                        getDataForm(e)
                                    }}>
                                </IonInput>
                            </IonItem>
                            <IonText>
                                <p className={errors.nombre? "login-valid-text" : "ion-hide"}>{errors.nombre}</p>
                            </IonText>
                        </IonCol>
                        <IonCol sizeLg='6' sizeXs='12' className="ABMClientsFormCol">
                            <IonLabel className="ion-margin-vertical ABMClientsFormLabel">
                                ID
                            </IonLabel>
                            <IonItem className="ABMClientsFormItem" lines='none'>
                                <IonInput
                                    type="text"
                                    placeholder="Ingrese el ID"
                                    className="ion-padding ABMClientsFormInput"
                                    id="input-id-add"
                                    name="id"
                                    value={id}
                                    onIonChange={(e) => {
                                        validationID();
                                        getDataForm(e)
                                    }}>
                                </IonInput>
                            </IonItem>
                            <IonText>
                                <p className={errors.id? "login-valid-text" : "ion-hide"}>{errors.id}</p>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeLg='6' sizeXs='12' className="ABMClientsFormCol">
                            <IonLabel className="ion-margin-vertical ABMClientsFormLabel">
                                Categoria
                            </IonLabel>
                            <IonItem className="ABMClientsFormItem" lines='none'>
                                <IonSelect
                                    interface="popover"
                                    interfaceOptions={customSelectOptions}
                                    placeholder="Seleccione la categoria"
                                    name="category"
                                    id="select-category-add"
                                    className="ABMClientsFormInput"
                                    value={category}
                                    onIonChange={(e) => {
                                        getDataForm(e)
                                    }}>
                                    <IonSelectOption value="BANCOS">
                                        NEWPAY
                                    </IonSelectOption>
                                    <IonSelectOption value="RETAIL">
                                        PAYWAY
                                    </IonSelectOption>
                                    <IonSelectOption value="INTERNO">
                                        PRISMA
                                    </IonSelectOption>
                                    <IonSelectOption value="ADMIN">
                                        ADMIN
                                    </IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        </IonCol>
                        <IonCol sizeLg='6' className="ABMClientsFormCol">
                            <IonLabel className="ion-margin-vertical ABMClientsFormLabel">
                                Mantenimiento
                            </IonLabel>
                            <IonToggle
                                className="ion-padding-start"
                                name="maintenance"
                                mode="ios"
                                color="secondary"
                                checked={maintenance}
                                onIonChange={getToggleForm}>
                            </IonToggle>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeLg='12' className="ABMClientsFormCol mobile-hidden">
                            <IonLabel className="ion-margin-vertical ABMClientsFormLabel">
                                Imagen
                            </IonLabel>
                            <Upload
                                initialImage={image}
                                imageSelectCallback={onImageUpload}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-margin-horizontal ion-margin-top ion-no-margin-bottom ion-justify-content-center">
                        <IonButton type="submit" fill="clear" onClick={createNewClient}>
                            <IonIcon icon={checkmarkCircleOutline} color="success" className="ion-padding ion-no-padding-bottom AMBClientsButton" />
                        </IonButton>
                        <IonButton fill="clear" onClick={props.backToTable}>
                            <IonIcon icon={closeCircleOutline} color="danger" className="ion-padding ion-no-padding-bottom AMBClientsButton" />
                        </IonButton>
                    </IonRow>
                </IonGrid>
            </IonCard>


            <Modal
                modalConfirmation={
                    {
                        textConfirmation: 'Cliente Creado Correctamente',
                        onDidDimissConfirmModal: () => dismissModal(),
                        isOpenModalConfirm: showModal,
                        onClickConfirmModal: () => dismissModal()
                    }
                }
            />

        </IonContent>
    );
}

//Redux state and actions dispatchers
interface StateTypes {

}

const mapStatetoProps = (state: StateTypes) => {
    return {

    }
};
interface dispatchTypes {
    getABMClients: () => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getABMClients: () => dispatch(GetABMClients())
    };
}

export default connect(mapStatetoProps, mapDispatchToProps)(AddABMClients);