import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { IonItem, IonList, IonLabel, IonRow, IonCol, IonButton } from '@ionic/react';

import { ClientImageDefaultStateI } from '../../store/mainInfo/clientImageReducer';
import { SessionDefaultStateI } from '../../store/session/SessionReducer';

import './SwtlistClientsModal.css';
import Modal from '../Modal/Modal';

interface Props extends StateTypes {
  parentCallback: any;
  clientSelected?: string;
  showModal: boolean;
  closeModalCallback: any;
}


const SwtListClientsModal: React.FC<Props> = (props): JSX.Element => {

  const [clientSelectToken, setClientSelectToken] = useState('');

   useEffect(() => {
    setClientSelectToken(sessionStorage.getItem("category_token")?? '')
    }, []);

  const returnChildrenModal = (): JSX.Element => {
    return (
      <>
        <IonList className='list-clients-modal-list'>
          <IonItem className="list-clients-modal-category" lines="none">
            <IonLabel className="list-clients-modal-category-label"> NEWPAY </IonLabel>
          </IonItem>
          {props.clientImage.loading === false && props.clientImage.clientImage &&
            props.clientImage.clientImage.map((client: any, i) => {
              return (
                client.service_category == "BANCOS" &&
                <IonItem key={i} button mode="md" onClick={() => props.parentCallback(client.service_name, client.service_category)} className={sessionStorage.getItem("client") == client.service_name ? "list-client-modal-selected list-clients-modal-item" : "list-clients-modal-item"} id={client.service_display_name + "MODAL"}>
                  <IonLabel> {client.service_display_name} </IonLabel>
                </IonItem>
              )
            })
          }
          {
            !clientSelectToken.includes("CONSULTA") &&
          <IonItem className="list-clients-modal-category" lines="none">
            <IonLabel className="list-clients-modal-category-label"> PAYWAY </IonLabel>
          </IonItem>
          }
          {props.clientImage.loading === false && props.clientImage.clientImage &&
            props.clientImage.clientImage.map((client: any, i) => {
              return (
                
                client.service_category === "RETAIL" &&
                <IonItem key={i} button mode="md" onClick={() => props.parentCallback(client.service_name, client.service_category)} className={sessionStorage.getItem("client") == client.service_name ? "list-client-modal-selected list-clients-modal-item" : "list-clients-modal-item"} id={client.service_display_name + "MODAL"}>
                  <IonLabel> {client.service_display_name} </IonLabel>
                </IonItem>
              )
            })
          }
          <IonItem className="list-clients-modal-category" lines="none">
            <IonLabel className="list-clients-modal-category-label"> PRISMA </IonLabel>
          </IonItem>
          {props.clientImage.loading === false && props.clientImage.clientImage &&
            props.clientImage.clientImage.map((client: any, i) => {
              return (
                client.service_category == "INTERNO" &&
                <IonItem key={i} button mode="md" onClick={() => props.parentCallback(client.service_name, client.service_category)} className={sessionStorage.getItem("client") == client.service_name ? "list-client-modal-selected list-clients-modal-item" : "list-clients-modal-item"} id={client.service_display_name + "MODAL"}>
                  <IonLabel> {client.service_display_name} </IonLabel>
                </IonItem>
              )
            })
          }
        </IonList>
        {sessionStorage.getItem("client") !== '' &&
          <IonRow className="ion-text-center">
            <IonCol size-lg="12" size-md="12">
              <IonButton className="list-clients-modal-button" fill="outline" size="small" onClick={() => props.closeModalCallback()}>Cancelar</IonButton>
            </IonCol>
          </IonRow>
        }

      </>
    )


  }

  return (
    <Modal
      modalChildren={
        {
          id: "",
          styles: "list-clients-modal",
          isOpen: props.showModal,
          backdropDimiss: false,
          children: returnChildrenModal()

        }
      }
    />
  )
}

//Redux state and actions dispatchers
interface StateTypes {
  session: SessionDefaultStateI,
  clientImage: ClientImageDefaultStateI
}

const mapStateToProps = (state: StateTypes) => {
  return {
    session: state.session,
    clientImage: state.clientImage,
  };
};

export default connect(mapStateToProps)(SwtListClientsModal)